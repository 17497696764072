// Globals
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Project imports
import options from 'ki-common/options';

// Website imports
import KiInput from 'components/KiInput';
import KiSelect, {KiCreatable} from 'components/KiSelect';
import KiCheckbox from 'components/KiCheckbox';

// Local imports
import ConstraintHelpers from './constraintHelpers';
import styles from './constraintFormStyles.theme.scss';
import validators from 'ki-common/validators';
import ccExclude from './validators/ccExclude';

const constraintTypes = [
	{
		label: 'Both',
		value: 'both',
	},
	{
		label: 'Funding',
		value: 'funding',
	},
	{
		label: 'Drawdown',
		value: 'repurchase',
	},
	// None should not be an option for a groupBy funding vehicle
	{
		label: 'None',
		value: 'none',
	},
];

const constraints = ccExclude.getConstraints();

const getLogicValues = dataType => {
	switch (dataType) {
		case 'numeric':
			return options.logic.filter(opt => !['in', 'not_in'].includes(opt.value));
		case 'string':
			return options.logic.filter(opt => ['=', '<>', 'in', 'not_in'].includes(opt.value));
		default:
			return options.logic;
	}
};

const getTargetValues = target => (Array.isArray(target) ? target : []);

const ExclusionForm = props => {
	const {constraintData} = props;

	const [formError, setFormError] = useState('');
	const [dataOptions, setDataOptions] = useState([]);
	const [logicValues, setLogicValues] = useState([]);

	useEffect(
		() => {
			setDataOptions(
				ConstraintHelpers.getCohortColumns(
					props.dataColumns,
					props.constraintData.dataColumn,
					true,
					props.isGroupBy,
					false,
					props.eligibleColumns
				)
			);
		},
		[props.dataColumns, props.constraintData.dataColumn, props.isGroupBy, props.eligibleColumns]
	);

	useEffect(
		() => {
			setLogicValues(getLogicValues(props.constraintData?.dataColumn?.dataType));
		},
		[props.constraintData?.dataColumn?.dataType]
	);

	useEffect(
		() => {
			setFormError(validators.validate(props.constraintData, constraints));
		},
		[props.constraintData]
	);

	useEffect(
		() => {
			props.updateFormHasError(!!formError);
		},
		[props.updateFormHasError, formError]
	);

	function removeUnwantedColumnNames(dataOptions) {
		return dataOptions.columnName != 'kiEligibleBalance' && dataOptions.columnName != 'kiIneligibleBalance';
	}

	return (
		<div className={styles.root}>
			<div className={styles.constraintFormBody}>
				{!props.isFundingAnalysisConstraint && (
					<div className={styles.selectWrapper}>
						<span className="theme-label">Constraint Type</span>
						<KiSelect
							isDisabled={props.isReadOnly || constraintData._id}
							classNamePrefix="aut-select"
							value={constraintTypes.find(opt => opt.value === constraintData.constraintType)}
							isClearable={false}
							options={props.isGroupBy ? constraintTypes.slice(0, 3) : constraintTypes}
							onChange={option => {
								props.setConstraintItem('constraintType', option.value);
								// Indicates that this MUST be an eligibility constraint
								if (option.value === 'none') {
									props.setConstraintItem('includeInEligibility', true);
								}
							}}
						/>
					</div>
				)}
				<div className={styles.selectWrapper}>
					<span className="theme-label">Data Column</span>
					<KiSelect
						key={constraintData.dataColumn}
						isDisabled={props.isReadOnly}
						classNamePrefix="aut-select"
						value={constraintData.dataColumn}
						isLoading={props.loadingColumns}
						isClearable={false}
						options={dataOptions.filter(removeUnwantedColumnNames)}
						onChange={dc => {
							props.setConstraintItem('dataColumn', dc);
						}}
						getOptionLabel={option => option.detailedDisplayName}
						getOptionValue={option => option._id}
					/>
				</div>
				<div className={styles.selectWrapper}>
					<span className="theme-label">Logic</span>
					<KiSelect
						key={constraintData.logic}
						isDisabled={!constraintData.dataColumn || props.isReadOnly}
						classNamePrefix="aut-select"
						value={constraintData.logic}
						isClearable={false}
						options={logicValues}
						onChange={l => {
							const keys = [];
							const values = [];
							keys.push('logic');
							values.push(l);
							keys.push('target');
							if (l.value === 'between') {
								values.push(['', '']);
							} else if (l.value === 'in' || l.value === 'not_in') {
								values.push([]);
							} else {
								values.push('');
							}
							props.setConstraintItem(keys, values);
						}}
					/>
				</div>
				{constraintData.logic && (
					<div className={styles.selectWrapper}>
						{['in', 'not_in'].includes(constraintData.logic?.value) && (
							<>
								<span className="theme-label">Target</span>
								<KiCreatable
									isDisabled={props.isReadOnly}
									isMulti={true}
									type="text"
									placeholder={'Enter a value...'}
									clearable={false}
									noResultsText={false}
									isNumberMasked={constraintData?.dataColumn?.dataType === 'numeric'}
									value={getTargetValues(constraintData?.target).map(t => ({
										value: t,
										label: t,
									}))}
									onChange={t => {
										props.setConstraintItem('target', t.map(t => t.value));
									}}
									errorMessage={formError?.target}
								/>
								{<div className={styles.ccConcentrationIncludesDateError}>{formError?.target}</div>}
							</>
						)}
						{constraintData.logic?.value === 'between' && (
							<div
								className={styles.selectWrapper}
								style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
							>
								<KiInput
									disabled={props.isReadOnly}
									type="text"
									label="Min"
									value={constraintData.target[0] || ''}
									isNumberMasked={_.get(constraintData, 'dataColumn.dataType') === 'numeric'}
									onChange={t => {
										const newValue = [t, constraintData.target[1]];
										props.setConstraintItem('target', newValue);
									}}
									error={formError?.target}
								/>
								<KiInput
									disabled={props.isReadOnly}
									type="text"
									label="Max"
									value={constraintData.target[1] || ''}
									isNumberMasked={_.get(constraintData, 'dataColumn.dataType') === 'numeric'}
									onChange={t => {
										const newValue = [constraintData.target[0], t];
										props.setConstraintItem('target', newValue);
									}}
									error={formError?.target}
								/>
							</div>
						)}
						{!['in', 'not_in', 'between'].includes(constraintData.logic.value) && (
							<KiInput
								disabled={props.isReadOnly}
								type="text"
								name="name"
								label={'Target'}
								isNumberMasked={_.get(constraintData, 'dataColumn.dataType') === 'numeric'}
								value={constraintData.target?.toString?.() || ''}
								onChange={t => {
									props.setConstraintItem('target', [t]);
								}}
								error={formError?.target}
							/>
						)}
					</div>
				)}
				{!props.isFundingAnalysisConstraint && (
					<div className={styles.selectWrapper}>
						<KiCheckbox
							disabled={props.isReadOnly || props.isGroupBy || constraintData.constraintType === 'none'}
							checked={constraintData.includeInEligibility}
							label="Include in Eligibility Status Calculation"
							onChange={val => {
								props.setConstraintItem('includeInEligibility', val);
							}}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

ExclusionForm.propTypes = {
	constraintData: PropTypes.object,
	setConstraintItem: PropTypes.func.isRequired,
	dataColumns: PropTypes.array,
	eligibleColumns: PropTypes.array,
	loadingColumns: PropTypes.bool,
	isGroupBy: PropTypes.bool,
	isReadOnly: PropTypes.bool,
	isFundingAnalysisConstraint: PropTypes.bool,
	updateFormHasError: PropTypes.func.isRequired,
};

ExclusionForm.defaultProps = {
	constraintData: {},
	dataColumns: [],
	eligibleColumns: [],
};

export default ExclusionForm;
