import PropTypes from 'prop-types';
import React from 'react';
import {connect, ReactReduxContext} from 'react-redux';
import KiSnackbar from 'components/KiSnackbar';
import socket from '../socket';
import ledgerSocket from '../socket/ledgerSocket';
import {Switch, Route, Redirect} from 'react-router-dom';
import Nav from 'components/Nav';
import TopNav from 'components/TopNav';
import Footer from 'components/Footer';
import {hideSnackbar} from 'state/actions/Snackbar';
import ReactGA from 'react-ga';
import classNames from 'classnames';
import {fetchNotificationsForCurrentUser} from 'containers/notificationList/actions';
import Modal from 'react-modal';
import _isEqual from 'lodash/isEqual';
import {Header as ABSHeader, Footer as ABSFooter} from '@moodysanalytics/cs-structured-ux-common';
window.expressionBuilder = require('ki-common/utils/expressionBuilder');
import {
	Submission,
	Submissions,
	Accounts,
	Calendars,
	DataExplorer,
	DatasetList,
	FundingVehicle,
	FundingVehicleList,
	FundingAnalysis,
	PoolMaintenance,
	DebtInputMaintenance,
	AssociatedData,
	AssociatedDataList,
	Dashboards,
	MyProfile,
	Reports,
	ReportDisplay,
	ReportDefinition,
	NotificationList,
	SystemInfo,
	KiComponents,
	BusinessFunctions,
	Mappings,
	AuditList,
	KiStyleGuide,
	Debt,
	DealStructures,
	Forecasting,
	Logs,
	Ledgers,
	LedgerExploration,
	LedgerManualEntry,
	LedgerGeneratePosts,
	LedgerConfigure,
	DatasetUtilities,
} from '../containers';
import {USER_GROUPS} from '../utils/userGroupsUtil';
import facilityReconciliationLogo from 'static/svgs/abs-lender-facility-reconciliation.svg';

const ProtectedRoute = ({component: Component, user, ...rest}) => {
	const protectedRoutes = new Map();
	//protectedRoutes.set('/submissions', ['SystemAdmins']);
	//protectedRoutes.set('/submissions/:id', ['SystemAdmins']);
	protectedRoutes.set('/datasets/create', ['SystemAdmins']);
	protectedRoutes.set('/datasets/create/:datasetId', ['SystemAdmins']);
	protectedRoutes.set('/datasets/:datasetId/logs', ['SystemAdmins']);
	protectedRoutes.set('/dataset/:datasetId/submission', ['SystemAdmins']);
	protectedRoutes.set('/dataset/:datasetId/submission/:submissionId', ['SystemAdmins']);
	protectedRoutes.set('/datasets/:datasetId/fundingVehicles', ['SystemAdmins']);
	protectedRoutes.set('/datasets/:datasetId/fundingVehicles/:id', ['SystemAdmins']);
	protectedRoutes.set('/datasets/:datasetId/fundingVehicles/:id/debts/:debtId', ['SystemAdmins']);
	protectedRoutes.set('/fundingVehicles', ['SystemAdmins']);
	protectedRoutes.set('/fundingVehicles/:id', ['SystemAdmins']);
	protectedRoutes.set('/poolMaintenance/:datasetId', ['SystemAdmins']);
	protectedRoutes.set('/debtInputMaintenance/:datasetId', ['SystemAdmins']);
	protectedRoutes.set('/associatedData/:datasetId', ['SystemAdmins']);
	protectedRoutes.set('/associatedDataList/:datasetId', ['SystemAdmins']);
	protectedRoutes.set('/accounts', ['SystemAdmins', 'UserAdmins']);
	protectedRoutes.set('/reports', ['SystemAdmins']);
	protectedRoutes.set('/reports/:reportGroupId', ['SystemAdmins']);
	protectedRoutes.set('/reports/definition/:id', ['SystemAdmins']);
	protectedRoutes.set('/reports/copy/:id', ['SystemAdmins']);
	protectedRoutes.set('/systemInfo', ['SystemAdmins']);
	protectedRoutes.set('/audit', ['SystemAdmins']);
	protectedRoutes.set('/ledgers', [USER_GROUPS.USERS, USER_GROUPS.USER_ADMINS, USER_GROUPS.SYSTEM_ADMINS]);
	protectedRoutes.set('/ledgers/:id/exploration', [
		USER_GROUPS.USERS,
		USER_GROUPS.USER_ADMINS,
		USER_GROUPS.SYSTEM_ADMINS,
	]);
	protectedRoutes.set('/ledgers/:id/manualEntry', [USER_GROUPS.SYSTEM_ADMINS]);
	protectedRoutes.set('/ledgers/:id/generatePosts', [USER_GROUPS.SYSTEM_ADMINS]);
	protectedRoutes.set('/ledgers/:id/configure', [USER_GROUPS.SYSTEM_ADMINS]);
	protectedRoutes.set('/FundingModelWizard', [USER_GROUPS.SYSTEM_ADMINS]);

	const userHasAccess = function(user, baseUrl) {
		if (protectedRoutes.has(baseUrl)) {
			for (const g of protectedRoutes.get(baseUrl)) {
				if (user.groups.includes(g)) return true;
			}
			return false;
		}
		return true; // not protected so let them pass
	};

	return (
		<Route
			{...rest}
			render={props =>
				userHasAccess(user, props.match.path) ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: '/sorry',
							state: {from: props.location},
						}}
					/>
				)
			}
		/>
	);
};

ProtectedRoute.propTypes = {
	match: PropTypes.any,
	location: PropTypes.any,
	component: PropTypes.any,
	user: PropTypes.object,
};

Modal.setAppElement('#root');

class App extends React.Component {
	static propTypes = {
		user: PropTypes.object.isRequired,
		snackbar: PropTypes.shape({
			action: PropTypes.string,
			type: PropTypes.string,
			msg: PropTypes.string.isRequired,
			active: PropTypes.bool.isRequired,
			timeout: PropTypes.number,
			autoClose: PropTypes.bool,
			className: PropTypes.string,
		}).isRequired,
		hideSnackbar: PropTypes.func.isRequired,
		app: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		notificationList: PropTypes.array.isRequired,
		fetchNotificationsForCurrentUser: PropTypes.func.isRequired,
	};

	static contextTypes = {
		store: PropTypes.object,
	};

	static defaultProps = {
		notificationList: [],
	};

	state = {
		drawerActive: false,
		drawerPinned: false,
		sidebarPinned: false,
		mainContentClassNames: 'main-content',
	};

	componentDidMount = () => {
		document.title = `${this.props.app.kiVersion}`;
		ReactGA.set({userId: this.props.user.userId});
		this.props.fetchNotificationsForCurrentUser();
	};

	componentDidUpdate = prevProps => {
		if (!_isEqual(prevProps, this.props)) {
			if (prevProps.location.pathname !== this.props.location.pathname) {
				ReactGA.pageview(window.location.pathname);
			}
			this.setState({
				mainContentClassNames: classNames({
					'main-content': true,
					'global-nav-open': this.props.app.globalNavOpen,
					'context-sidebar-open': this.props.app.contextSidebarOpen,
				}),
			});
		}
	};

	componentWillUnmount = () => {
		socket.selfDestruct();
		ledgerSocket.selfDestruct();
	};

	toggleDrawerActive = () => {
		this.setState({drawerActive: !this.state.drawerActive});
	};

	toggleDrawerPinned = () => {
		this.setState({drawerPinned: !this.state.drawerPinned});
	};

	toggleSidebar = () => {
		this.setState({sidebarPinned: !this.state.sidebarPinned});
	};

	mainSectionHeight =
		505 + (window.LEDGER_ENABLED === 'true' ? 57 : 0) + (window.FORECASTING_ENABLED === 'true' ? 57 : 0);

	render() {
		const {app, user, snackbar, hideSnackbar, history, location, notificationList} = this.props;
		const {mainContentClassNames} = this.state;
		return (
			<div className="root-container">
				<ReactReduxContext.Consumer>
					{({store}) => {
						// Temporary fix for sockets since react-redux v7 doesn't pass store from context anymore
						if (!socket.store) {
							socket.initialize(store);
						}
						if (!ledgerSocket.store) {
							ledgerSocket.initialize(store);
						}
					}}
				</ReactReduxContext.Consumer>
				{window.ABS_SSO_ENABLED === 'true' ? (
					<ABSHeader
						userProfile={user.absSuiteProfile}
						beforeLogout={() => {
							window.location.assign('web/oidc/logout');
						}}
						issuerUrl={window.ABS_ISSUER_URL}
						identityUrl={window.ABS_IDENTITY_URL}
						lenderUrl={window.ABS_LENDER_URL}
						administrationUrl={window.ABS_ADMINISTRATION_URL}
						AppBarProps={{elevation: 0}}
						absApp="capital_markets"
						disabledApps={[
							!(window.FUNDING_APPROVALS_ENABLED === 'true' || window.LEDGER_APPROVALS_ENABLED === 'true')
								? 'approvals'
								: '',
						]}
						buildNumber={window.ABS_FOOTER_BUILD_NUMBER || '3.3.0'}
						walkMeSrc={window.WALKME_SCRIPT_URL || undefined}
						logo={window.USE_FACILITY_RECONCILIATION_BRANDING ? facilityReconciliationLogo : undefined}
						logoAlt={window.USE_FACILITY_RECONCILIATION_BRANDING ? 'Facility Reconciliation' : undefined}
						capitalMarketsLabel={
							window.USE_FACILITY_RECONCILIATION_BRANDING ? 'Facility Reconciliation' : undefined
						}
						availableApps={window.AVAILABLE_APPS || undefined}
					/>
				) : (
					<TopNav user={user} router={history} notificationList={notificationList} />
				)}
				<section
					style={{
						display: 'flex',
						flexFlow: 'row nowrap',
						overflow: 'auto',
						flex: 1,
						minHeight: this.mainSectionHeight,
					}}
				>
					<Nav path={location.pathname} />
					<div className={mainContentClassNames}>
						<Switch>
							<Redirect exact from="/" to="/fundingAnalysis" />
							<ProtectedRoute path="/accounts" component={Accounts} user={user} />
							<ProtectedRoute path="/datasets/:datasetId/logs" component={Logs} user={user} />
							<ProtectedRoute
								path="/datasets/:datasetId/submission/:submissionId"
								component={Submission}
								user={user}
							/>
							<ProtectedRoute path="/datasets/create/:datasetId" component={Submissions} user={user} />
							<ProtectedRoute path="/datasets/create" component={Submission} user={user} />
							<Route exact path="/dataExplorer/:datasetId?" component={DataExplorer} />
							<Route path="/datasets/:datasetId/debt" component={Debt} />
							<ProtectedRoute path="/calendars" component={Calendars} user={user} />
							<ProtectedRoute path="/forecasting" component={Forecasting} user={user} />
							<ProtectedRoute
								path="/datasets/:datasetId/fundingVehicles/:id/:group/:section/:debtId"
								component={FundingVehicle}
								user={user}
							/>
							<ProtectedRoute
								path="/datasets/:datasetId/fundingVehicles/:id/:group/:section"
								component={FundingVehicle}
								user={user}
							/>
							<ProtectedRoute
								path="/datasets/:datasetId/fundingVehicles/:id"
								component={FundingVehicle}
								user={user}
							/>
							<ProtectedRoute
								path="/datasets/:datasetId/fundingVehicles"
								component={FundingVehicleList}
								user={user}
							/>
							<Route path="/datasets" component={DatasetList} />
							<ProtectedRoute exact path="/fundingVehicles" component={FundingVehicleList} user={user} />
							<ProtectedRoute
								path="/poolMaintenance/:datasetId"
								component={PoolMaintenance}
								user={user}
							/>
							<ProtectedRoute
								path="/debtInputMaintenance/:datasetId"
								component={DebtInputMaintenance}
								user={user}
							/>
							<ProtectedRoute path="/associatedData/:datasetId" component={AssociatedData} user={user} />
							<ProtectedRoute
								path="/associatedDataList/:datasetId"
								component={AssociatedDataList}
								user={user}
							/>
							<Route path="/dashboards" component={Dashboards} />
							<Route path="/myProfile" component={MyProfile} />
							<Route path="/reports/display/:id" component={ReportDisplay} />
							<ProtectedRoute
								exact
								path="/reports/definition/:id"
								component={ReportDefinition}
								user={user}
							/>
							<ProtectedRoute exact path="/reports/copy/:id" component={ReportDefinition} user={user} />
							<ProtectedRoute path="/reports/definition" component={ReportDefinition} user={user} />
							<Route path="/reports/:reportGroupId" component={Reports} />
							<Route path="/reports" component={Reports} />
							<Route path="/notifications" component={NotificationList} />
							<Route path="/businessFunctions" component={BusinessFunctions} />
							<Route path="/mappings/:datasetId/:mapType?" component={Mappings} route={Route} />
							<Route
								path="/sorry"
								render={() => (
									<h5 className="no-auth-msg">
										You have Requested Access to a Page That is Restricted. Please Contact Your
										System Administrator for Assistance.
									</h5>
								)}
							/>
							<Route path="/systemInfo" component={SystemInfo} />
							{window.DEV_TOOLS_ENABLED === 'true' && (
								<Route path="/debug/kiComponents" component={KiComponents} />
							)}
							{window.DEV_TOOLS_ENABLED === 'true' && (
								<Route path="/styleGuide" component={KiStyleGuide} />
							)}
							<ProtectedRoute path="/audit" component={AuditList} user={user} />
							<ProtectedRoute path="/dealStructures/:id/:fvId?" component={DealStructures} user={user} />
							<ProtectedRoute path="/ledgers/:id/exploration" component={LedgerExploration} user={user} />
							<ProtectedRoute path="/ledgers/:id/manualEntry" component={LedgerManualEntry} user={user} />
							<ProtectedRoute
								path="/ledgers/:id/generatePosts"
								component={LedgerGeneratePosts}
								user={user}
							/>
							<ProtectedRoute path="/ledgers/:id/configure" component={LedgerConfigure} user={user} />
							<ProtectedRoute path="/ledgers" component={Ledgers} user={user} />
							<Route
								path="/fundingAnalysis/:datasetId/bookmark/:bookmarkId"
								component={FundingAnalysis}
								user={user}
								app={app}
							/>
							<Route
								path="/fundingAnalysis/:datasetId"
								component={FundingAnalysis}
								user={user}
								app={app}
							/>
							<Route path="/fundingAnalysis" component={FundingAnalysis} user={user} app={app} />
							<Route path="/datasetUtilities" component={DatasetUtilities} user={user} app={app} />
						</Switch>
					</div>
				</section>
				{window.ABS_SSO_ENABLED === 'true' ? (
					<ABSFooter buildNumber={window.ABS_FOOTER_BUILD_NUMBER || '3.3.0'} />
				) : (
					<Footer />
				)}
				<KiSnackbar
					className={snackbar.className}
					active={snackbar.active}
					label={snackbar.msg}
					timeout={snackbar.timeout || 4000}
					onClick={hideSnackbar}
					onTimeout={hideSnackbar}
					autoClose={snackbar.autoClose}
				/>
			</div>
		);
	}
}

const mapPropsToState = state => ({
	user: state.user,
	snackbar: state.snackbar,
	app: state.app,
	notificationList: state.notificationList.data,
});

export default connect(
	mapPropsToState,
	{
		hideSnackbar,
		fetchNotificationsForCurrentUser,
	}
)(App);
